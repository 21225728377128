<template>
    <Box :headerText="this.getTitle" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <b-spinner v-if="!mainDevice" />
                <div v-else>
                    <exec-meter-info
                        class="mb-2"
                        :mainDevice="mainDevice"
                        :order="order"
                        :devicePool="devicePool"
                        :category="'Wandler'"
                        :showRelatedDeviceInfo="true"
                    />
                    <b-form>
                        <div v-for="(wandler, index) in wandlersAlreadyInstalled" :key="wandler.deviceID">
                            <strong class="mt-2">{{ `${wandler.deviceSuffix} ${index + 1}` }}</strong>
                            <b-row class="text-left no-gutters">
                                <b-col cols="4">Werk-Nr.:</b-col>
                                <b-col cols="6" v-if="wandler?.internalNo">{{ wandler?.internalNo }}</b-col
                                ><b-col v-else>-</b-col>
                            </b-row>
                            <b-row class="text-left no-gutters">
                                <b-col cols="4">Fabrik-Nr.:</b-col>
                                <b-col cols="6" v-if="wandler?.factoryNo">{{ wandler?.factoryNo }}</b-col
                                ><b-col v-else>-</b-col>
                            </b-row>
                            <b-row class="text-left no-gutters">
                                <b-col cols="4">Typ:</b-col>
                                <b-col cols="6" v-if="wandler?.deviceType">{{ wandler?.deviceType }}</b-col
                                ><b-col v-else>-</b-col>
                            </b-row>
                            <b-row class="text-left no-gutters">
                                <b-col cols="4 ">Beschreibung:</b-col>
                                <b-col cols="6" v-if="wandler?.description">{{ wandler?.description }}</b-col
                                ><b-col v-else>-</b-col>
                            </b-row>
                            <b-form-group label="Aktionen:" class="mt-1">
                                <b-form-radio
                                    v-model="wandler.actionType"
                                    value="0"
                                    @change="onClickWandlerData(wandler, 'confirmed')"
                                    :disabled="!actAuftragDetail.isExecutable"
                                >
                                    <div>Die Daten sind korrekt.</div>
                                </b-form-radio>
                                <b-form-radio
                                    v-model="wandler.actionType"
                                    value="1"
                                    @change="onClickWandlerData(wandler, 'adjust')"
                                    :disabled="!actAuftragDetail.isExecutable"
                                >
                                    Daten gemäss Foto im Backoffice prüfen / anpassen.
                                </b-form-radio>
                                <b-form-radio
                                    v-model="wandler.actionType"
                                    value="2"
                                    @change="onClickWandlerData(wandler, 'remove')"
                                    :disabled="!actAuftragDetail.isExecutable"
                                >
                                    Wandler ist nicht vorhanden.
                                </b-form-radio>
                            </b-form-group>
                            <div v-if="wandler.actionType === 1">
                                <PhotoUpload
                                    :key="wandler.internalNo"
                                    app="rellout"
                                    entity="devicepool"
                                    :entityId="actAuftragDetail.devicePoolID"
                                    refEntity="auftragdetail"
                                    :refEntityId="actAuftragDetail.auftragDetailID"
                                    :refEntityKey="`wandler_internalNo_${wandler.internalNo}`"
                                    folder="devices"
                                    :fileDescription="
                                        `Auftrag ${$route.params.orderId} - Wandlerprüfung - Wandlerfoto ${wandler.internalNo} `
                                    "
                                    :showImages="true"
                                    :assetDelete="true"
                                    @assetItems="onAssetItems"
                                    @assetDeleted="onAssetDeleted"
                                    :showLabelForCaptureTypes="true"
                                    :captureTypes="['camera', 'gallery']"
                                    :isDisabled="false"
                                />
                            </div>
                        </div>
                    </b-form>
                </div>
                <div
                    v-for="(newWandler, index) in wandlersToBeAdded"
                    :key="'newWandler' + index"
                    class="mt-2 align-items-center"
                >
                    <strong class="mt-2">Wandler {{ getOriginalIndex(newWandler) + 1 }}</strong>
                    <b-row class="text-left no-gutters align-items-center">
                        <b-col cols="4">Werk-Nr.:</b-col>
                        <b-col cols="6">
                            <b-form-input
                                v-model="newWandler.internalNo"
                                type="text"
                                placeholder="Werk-Nr. eingeben"
                                :disabled="!actAuftragDetail.isExecutable"
                                :state="isInputFormValid(newWandler.internalNo, index)"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="validationErrors.newWandlers[index]">
                                {{ validationErrors.newWandlers[index] }}
                            </b-form-invalid-feedback>
                        </b-col>
                        <b-col cols="2" class="text-right">
                            <font-awesome-icon
                                :icon="['fas', 'trash']"
                                v-on:click="onClickNewWandlerDelete(newWandler, index)"
                                class="ml-2 re-icon "
                            />
                        </b-col>
                    </b-row>
                    <b-form-group>
                        <b-form-radio
                            v-model="newWandler.deviceSuffix"
                            value="Stromwandler"
                            :disabled="!actAuftragDetail.isExecutable"
                        >
                            Stromwandler
                        </b-form-radio>
                    </b-form-group>
                    <div>
                        <PhotoUpload
                            :key="'newWandler' + index"
                            app="rellout"
                            entity="devicepool"
                            :entityId="actAuftragDetail.devicePoolID"
                            refEntity="auftragdetail"
                            :refEntityId="actAuftragDetail.auftragDetailID"
                            :refEntityKey="`wandler_internalNo_${newWandler.internalNo}`"
                            folder="devices"
                            :fileDescription="
                                `Auftrag ${$route.params.orderId} - Wandlerprüfung - Wandlerfoto ${newWandler.internalNo} `
                            "
                            :showImages="true"
                            :assetDelete="true"
                            @assetItems="onAssetItems"
                            @assetDeleted="onAssetDeleted"
                            :showLabelForCaptureTypes="true"
                            :captureTypes="['camera', 'gallery']"
                            :isDisabled="!compInternalNoExists(index)"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-end px-3 py-3">
                    <b-button v-if="!isLoading" @click="onClickWandlerAdd" :disabled="!actAuftragDetail.isExecutable">
                        <font-awesome-icon :icon="['fas', 'plus']" size="lg" /> Weiteren Wandler erfassen
                    </b-button>
                </div>
            </div>
            <div>
                <strong class="mt-4">Prüfklemmen</strong>
                <b-form class="mt-2 ">
                    <b-form-checkbox
                        v-show="[3, 5, 7].includes(getNetzebene)"
                        :disabled="!actAuftragDetail.isExecutable"
                        v-model="formPruefKlemmen.currentBridgesOpenChecked"
                    >
                        Strombrücken geöffnet
                    </b-form-checkbox>
                    <b-form-checkbox
                        class="mt-2"
                        v-show="[3, 5].includes(getNetzebene)"
                        :disabled="!actAuftragDetail.isExecutable"
                        v-model="formPruefKlemmen.voltagePathClosedChecked"
                    >
                        Spannungspfad geschlossen
                    </b-form-checkbox>
                </b-form>
            </div>
            <ExecSectionErledigt
                :isBusy="compIsBusy"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import PhotoUpload from "@/components/general/PhotoUpload";

import ExecMeterInfo from "@/components/execution/ExecMeterInfo.vue";
import constants from "@/constants/constants";

export default {
    name: "Step_7_3_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        PhotoUpload,
        ExecMeterInfo,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
        devicePool: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            assetItems: { images: [] },
            device: null,
            deviceWandler: null,
            formPruefKlemmen: { currentBridgesOpenChecked: false, voltagePathClosedChecked: false },
            isBusy: false,
            isLoading: false,
            mainDevice: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            suffixOrder: ["Stromwandler", "Spannungswandler", "Kombiwandler"],
            validationErrors: {
                newWandlers: {},
            },
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compIsBusy() {
            return this.isBusy;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            preconditionsLocalDetails.push({
                status: this.compEachWandlerHasValidActionType ? "FULFILLED" : "OPEN",
                message: this.compEachWandlerHasValidActionType
                    ? "Für jeden Wandler wurde eine Aktion ausgewählt."
                    : "Bitte für jeden Wandler eine Aktion auswählen.",
            });

            preconditionsLocalDetails.push({
                status: this.isWandlersToBeAddedFormValid ? "FULFILLED" : "OPEN",
                message: this.isWandlersToBeAddedFormValid
                    ? "Für jeden hinzuzufügenden Wandler wurde eine Werk-Nr. hinterlegt."
                    : "Für jeden hinzuzufügenden Wandler muss eine Werk-Nr. hinterlegt sein.",
            });

            preconditionsLocalDetails.push({
                status: this.compAllExistingOrNewWandlersHaveImages ? "FULFILLED" : "OPEN",
                message: this.compAllExistingOrNewWandlersHaveImages
                    ? "Für jeden anzupassenden Wandler wurde ein Foto hochgeladen."
                    : "Bitte für jeden anzupassenden Wandler ein Foto hochladen.",
            });

            if ([3, 5, 7].includes(this.getNetzebene)) {
                preconditionsLocalDetails.push({
                    status: this.formPruefKlemmen.currentBridgesOpenChecked ? "FULFILLED" : "OPEN",
                    message: this.formPruefKlemmen.currentBridgesOpenChecked
                        ? "Checkbox 'Strombrücken geöffnet' bestätigt."
                        : "Bitte Checkbox 'Strombrücken geöffnet' bestätigen.",
                });
            }
            if ([3, 5].includes(this.getNetzebene)) {
                preconditionsLocalDetails.push({
                    status: this.formPruefKlemmen.voltagePathClosedChecked ? "FULFILLED" : "OPEN",
                    message: this.formPruefKlemmen.voltagePathClosedChecked
                        ? "Checkbox 'Spannungspfad geschlossen bestätigt."
                        : "Bitte Checkbox 'Spannungspfad geschlossen' bestätigen.",
                });
            }

            return preconditionsLocalDetails;
        },
        compAllExistingOrNewWandlersHaveImages() {
            if (this.deviceWandler) {
                const adjustedWandlers = this.deviceWandler?.filter(
                    (wandler) => wandler.actionType === 1 // adjust
                );
                return adjustedWandlers?.every((wandler) =>
                    this.assetItems.images.some((image) => image.refEntityKey.includes(wandler.internalNo))
                );
            }
            return false;
        },
        compEachWandlerHasValidActionType() {
            return (
                this.deviceWandler?.every((wandler) => "actionType" in wandler) &&
                this.deviceWandler?.every((wandler) => wandler.actionType !== undefined)
            );
        },
        compInternalNoExists() {
            return (index) => {
                return !!this.wandlersToBeAdded[index].internalNo;
            };
        },
        getNetzebene() {
            const netzebeneValue = this.device?.deviceInfos?.find((deviceInfo) => deviceInfo.name === "Netzebene")
                ?.value;
            return Number(netzebeneValue ?? 7);
        },
        getTitle() {
            this.actAuftragDetail?.action?.title;
            if ([3, 5].includes(this.getNetzebene)) {
                return this.actAuftragDetail?.action?.title + " - MS/HS";
            }
            if (this.getNetzebene === 7) {
                return this.actAuftragDetail?.action?.title + " - NS";
            }
            return this.actAuftragDetail?.action?.title;
        },
        isWandlersToBeAddedFormValid() {
            const numberOfKeys = Object.keys(this.validationErrors.newWandlers).length;
            return numberOfKeys === 0;
        },
        isInputFormValid() {
            return (internalNo, index) => {
                if (!internalNo) {
                    this.$set(this.validationErrors.newWandlers, index, "Die Angabe einer Werk-Nr. ist zwingend.");
                    return false;
                }
                if (!/^\d+$/.test(internalNo)) {
                    this.$set(this.validationErrors.newWandlers, index, "Werk-Nr. muss eine Zahl sein");
                    return false;
                }
                this.$delete(this.validationErrors.newWandlers, index);
                return true;
            };
        },
        wandlersToBeAdded() {
            return this.deviceWandler?.filter(
                (wandler) =>
                    wandler.actionType === constants.actionTypesWandler.find((action) => action.type === "add").value
            );
        },
        wandlersAlreadyInstalled() {
            return this.deviceWandler?.filter(
                (wandler) =>
                    wandler.actionType !== constants.actionTypesWandler.find((action) => action.type === "add").value
            );
        },
    },
    async mounted() {
        this.isLoading = true;

        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];

        const auftragDetails731 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        await this.setActAuftragDetail(auftragDetails731);

        this.device = (await devicesAPI.getSingle(this.actAuftragDetail.deviceID, { includeDeviceInfos: true })).data;

        if (
            this.actAuftragDetail.value &&
            this.actAuftragDetail.status > constants.auftragDetailStatus.DONE_CLOSED &&
            this.actAuftragDetail.status < constants.auftragDetailStatus.SKIPPED
        ) {
            try {
                const parsedJSON = JSON.parse(this.actAuftragDetail.value);
                const wandlerList = parsedJSON.wandler;
                this.formPruefKlemmen = parsedJSON.formPruefKlemmen;
                this.deviceWandler = wandlerList.map((wandler) => {
                    return {
                        deviceID: wandler?.deviceID,
                        internalNo: wandler?.internalNo,
                        factoryNo: wandler?.factoryNo,
                        deviceType: wandler?.deviceType,
                        description: wandler?.description,
                        actionType: wandler?.actionType,
                        deviceSuffix: wandler?.deviceSuffix,
                    };
                });
            } catch (error) {
                const errorMessage = "Die gespeicherten Daten aus Schritt 7-3-1 konnten nicht geladen werden.";
                this.displayToast("DANGER", errorMessage);
            }
        } else {
            this.deviceWandler = await devicesAPI.get({
                devicePoolID: this.$route.params.devicePoolId,
                deviceSuffix: "Stromwandler",
                includeDeviceInfos: false,
                fromDate__lte: "2050-12-31",
                toDate__gte: "2050-12-31",
                bezugheAnlagheObjectID: this.device.bezugheAnlagheObjectID,
            });
        }

        this.sortWandlerList();

        this.mainDevice = await this.fetchRelatedDeviceDetails();

        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        async fetchRelatedDeviceDetails() {
            const deviceResp = await devicesAPI.getRelatedDevice({
                id: this.actAuftragDetail.deviceID,
                devicePoolId: this.$route.params.devicePoolId,
                auftragId: this.$route.params.orderId,
            });
            //The deviceID associated with the auftragDetails of this step
            //is the same as that of the old meter. We therefore retrieve the
            //information from the new installed meter.
            return deviceResp?.data?.newDevice;
        },
        getOriginalIndex(obj) {
            return this.deviceWandler?.indexOf(obj);
        },
        onClickWandlerData(wandler, value) {
            wandler.actionType = constants.actionTypesWandler.find((action) => action.type === value).value;
        },
        onClickNext() {
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        onClickPrevious() {
            //TODO: Adjust the actionDetail.StepPrevious = 7
            this.navigateToStepPrevious = "7";
        },
        onClickErledigt() {
            this.isBusy = true;

            let wandlerList = [];
            for (const wandler of this.deviceWandler) {
                wandlerList.push({
                    deviceID: wandler?.deviceID,
                    internalNo: wandler?.internalNo,
                    actionType: wandler?.actionType,
                    factoryNo: wandler?.factoryNo,
                    deviceType: wandler?.deviceType,
                    description: wandler?.description,
                    deviceSuffix: wandler?.deviceSuffix,
                });
            }

            const wandlerRequireISEActivity = wandlerList.filter(
                (wandler) =>
                    wandler.actionType !==
                    constants.actionTypesWandler.find((action) => action.type === "confirmed").value
            );

            let auftragDetailStatus = constants.auftragDetailStatus.DONE_CONDITION_1;

            if (wandlerRequireISEActivity.length > 0) {
                //IS-E activity required
                auftragDetailStatus = constants.auftragDetailStatus.DONE_CONDITION_2;
            }

            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/status",
                    value: auftragDetailStatus,
                },
                {
                    op: "replace",
                    path: "/value",
                    value: JSON.stringify({
                        wandler: wandlerList,
                        formPruefKlemmen: this.formPruefKlemmen,
                    }),
                },
            ];
            auftragDetailsAPI
                .patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail)
                .then(() => {
                    this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        onClickWandlerAdd() {
            const newWandler = {
                internalNo: null,
                deviceSuffix: "Stromwandler",
                device: "Wandler",
                actionType: constants.actionTypesWandler.find((action) => action.type === "add").value,
            };
            this.deviceWandler.push(newWandler);
        },
        onClickNewWandlerDelete(wandler, index) {
            this.$delete(this.validationErrors.newWandlers, index);
            this.deviceWandler = this.deviceWandler.filter((item) => item !== wandler);
        },
        onAssetItems(items) {
            if (items.images) {
                this.assetItems.images.push(...items.images);
            }
        },
        onAssetDeleted(deletedItem) {
            this.assetItems.images = this.assetItems.images.filter((item) => item.id !== deletedItem.assetId);
        },
        sortWandlerList() {
            this.deviceWandler.sort((a, b) => {
                // Compare by deviceSuffix according to the specified order
                const orderA = this.suffixOrder.indexOf(a.deviceSuffix);
                const orderB = this.suffixOrder.indexOf(b.deviceSuffix);
                if (orderA !== orderB) {
                    return orderA - orderB;
                }

                // If deviceSuffix is the same, compare by internalNo
                return a.internalNo.localeCompare(b.internalNo);
            });
        },
    },
};
</script>
